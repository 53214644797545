/* html,
body {
  height: 100%;
} */

html {
  height: 100%;
  background: #f8f8f8;
}
body {
  font-family: "Nunito",sans-serif !important;
  font-size: .8rem;
  font-weight: 400;
  color: #3a3a3a;
  background: #f8f8f8;
}

table th {
  white-space: nowrap;
}

.default-pointer {
  cursor: pointer;
  color: white;
}

.default-pointer:hover {
  color: #a1a1a1;
}

.style-chart-position {
  height: 100px;
  flex: 1;
  position: relative;
}

.chart-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.wrap-column-chart {
  height: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wrap-pie-chart {
  height: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wrap-world-chart {
  height: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.filter-paper-with-padding {
  padding: 25px;
  background-color: #eeeeee !important;
}

.filter-paper {
  background-color: #eeeeee !important;
}

.main-filter-wrapper {
  /* background-color: #eeeeee !important; */
}

.filter-content {
  background-color: #eeeeee !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 50px;
}

.filter-wrapper {
  background-color: #eeeeee !important;
  overflow-x: overlay;
  margin-right: 10px;
  z-index: 1;
}

.filter-wrapper::-webkit-scrollbar {
  width: 0px;
}

.text-bold {
  font-weight: 700 !important;
}

.circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  border-radius: 50%;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.table-custom {
  padding: 30px !important;
  overflow-x: scroll !important;
}

.table-custom tbody tr:nth-child(odd) {
  background-color: white !important;
}

.table-custom tbody tr:nth-child(even) {
  background-color: white !important;
}

.table-custom .table-responsive {
  display: flex;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
}

.table-custom {
  padding: 2px 16px !important;
  flex-direction: row !important;
  display: flex !important;
}

.table-custom {
  /* width:400px !important; */
  margin: 10px !important;
}

.table-custom .tbody-custom {
  display: inline-block;
  height: 180px;
  overflow-y: scroll;
  width: 100%;
}

.table-custom .thead-custom .tr-custom,
.tfoot-custom,
.tbody-custom .tr-custom {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-custom .tbody-custom::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #ffffff;
}

.table-custom .tbody-custom::-webkit-scrollbar {
  width: 1px;
  background-color: #f5f5f5;
  position: fixed;
}

.table-custom .tbody-custom::-webkit-scrollbar-thumb {
  background-color: #221b11;
}

.table-responsive {
  height: 400px;
  overflow: scroll;
}

thead.sticky-thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: -1px;
}

/* tbody {
  display:block;
  height:400px;
  overflow:auto;
}
thead, tbody tr {
  display:table;
  overflow-x: scroll;
  width:100%;
  table-layout:fixed;
} */

/* table {
  border-collapse: collapse;
  width: 540px;
  text-align:center;
  display: block;
}
thead {
  background-color: #EFEFEF;
}
tbody {
  display: block;
}
tbody {
overflow-y: scroll;
overflow-x: scroll;
  height: 140px;
}
td, th {
  min-width: 100px;
  height: 25px;
  border: dashed 1px lightblue;
} */

/* .tabWrap {
  height: 500px;
  margin-right: 30px;
  margin-bottom: 0;
  overflow: scroll;
  width: 100%;
} */

.multi-select {
  --rmsc-gray: gray;
}

.multi-select.disabled {
  --rmsc-bg: #f2f2f2 !important;
}

.multi-select.is-not-clear
  .dropdown-container
  .dropdown-heading
  .clear-selected-button {
  display: none !important;
}

.multi-select.disabled
  .dropdown-container
  .dropdown-heading
  .clear-selected-button {
  display: none !important;
}

.multi-select.disabled
  .dropdown-container
  .dropdown-heading
  .dropdown-heading-value {
  color: grey !important;
}

label.select-item:not(.form-check-label):not(.custom-file-label) {
  font-weight: normal;
}

label.select-item:not(.form-check-label):not(.custom-file-label) span {
  margin-left: 5px;
}

label.select-item {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #d2d2d2;
}

label.select-item div.item-renderer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container-fluid::-webkit-scrollbar {
  background-color: transparent;
}

.MuiFormLabel-asterisk {
  color: rgb(220, 53, 69);
}

.react-select.header .react-select__control {
  border-radius: 10px !important;
  background-color: #f8f8f8;
}

.react-select.header .react-select__control {
  background-color: #f8f8f8 !important;
}

.react-select.header .react-select__value-container {
  background-color: transparent !important;
}

.react-select.header .react-select__single-value {
  color: #3a3a3a !important;
  font-size: 13px;
  text-align: left !important;
}

.react-select.header .react-select__menu,
.react-select.header.react-select__menu {
  border-radius: 5px !important;
  border-top: 1px solid #f87506 !important;
}

.react-select.header .react-select__menu-list {
  color: #878787;
  text-align: left;
  border-top: 0px !important;
  font-size: 13px;
  border-radius: 5px !important;
}

.hovered:hover {
  color: #3a3a3a !important;
}

.highcharts-container,
.highcharts-root {
  width: 100% !important;
}

.text-no-wrap {
  white-space: nowrap;
}

.nav a.disabled-nav:hover {
  cursor: default;
  text-decoration: none;
  color: #8f8f8f !important;
}

.nav a.disabled-nav {
  color: #8f8f8f !important;
}

.nav {
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
}

.nav-tabs {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.custom-datepicker {
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  height: 40px !important;
  border-radius: 5px !important;
  border-color: rgb(204, 204, 204) !important;
}

.theme-colors, .theme-button {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%) !important;
}

.main-menu {
  border-radius: 0 !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%) !important;
}

.react-datepicker__triangle {
  display: none;  
}

.ps {
  overflow: unset !important;
}

.custom-dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.custom-dot.green {
  background-color: #82fc6f;
}
.custom-dot.red {
  background-color: #fa5252;
}
.custom-dot.blue {
  background-color: #6fdcfc;
}
.custom-dot.gray {
  background-color: #c4c4c4;
}

.react-datepicker__close-icon::after {
  background-color: rgba(248, 117, 6, 0.6) !important;
}

.text-red {
  color: #fa5252 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-opacity-popover .popover {
  width: 500px !important;
  max-width: 500px !important;
  opacity: 1 !important;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #221b11;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
}